// styles.scss
.testing {width: percentage(6/7);}

//Khai báo các biến
$main_color: #0EB0EE;
$second_color: #003470;
$three_color: #003470;
$font_title: 'Josefin Sans', sans-serif;

@mixin transition_3s {
	transition: all .3s;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
}

@mixin transition_5s {
	transition: all .5s;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
}

@mixin radius_2 {
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
}

//Khai báo font
@font-face {
    font-family: UTM Avo;
    src: url("../../font/UTM Avo.ttf");
}

@font-face {
    font-family: UTM AvoBold;
    src: url("../../font/UTM AvoBold.ttf");
}

.taskbar-m {
	background: $main_color;
}

.menu-m {
	.logo-m {
		a {
			img {
				padding: 10px 0;
				width: 80%;
			}
		}
	}
}

.logo_mobile {
	.container-vina {
		.row {
			text-align: center;    		
    		a {
    			img {
    				width: 80%;
    				padding: 30px 0;
    			}
    		}
		}		
	}
}

.mobile_search {
	form {
		margin: 0 20px 20px;
		input {
			width: calc(100% - 32px);
		    color: #333;
		    line-height: 32px;
		    text-indent: 10px;
		    outline: none;
		    top: 0;
		    right: 32px;
		    border: 1px solid #ddd;
		    transition: all .3s;
		    -webkit-transition: all .3s;
		}
		button {
			float: right;
		    width: 32px;
		    text-align: center;
		    background: transparent;
		    background: $main_color;
		    outline: none;
		    border-left: 0;
		    transition: all .3s;
		    -webkit-transition: all .3s;
		    i {
		    	color: #fff; 
		    	line-height: 34px; 
		    	font-size: 16px;
		    }
		}
	}
}

.headers {
	background: $main_color;
	padding: 25px 0;
	.container-vina {
		.row {
			.logo {
				a {
					img {
						width: 220px;
						height: unset;
						position: relative;
						margin-right: unset;
						top: unset;
					}
				}
			}
			.item {
				>p {
					color: #FFF;
					font-size: 15px;
					font-family: "UTM Avo" ,sans-serif;
					padding-left: 50px;
					background: url(../images/bg_icon1.png) no-repeat left top;
					background-size: 35px;
					>span {
						font-family: "Roboto" ,sans-serif;
						font-weight: bold;
					}
				}
			}
			.email {
				>p {
					background: url(../images/bg_icon2.png) no-repeat left top;
				}
			}
			.hotline {
				>p {
					background: url(../images/bg_icon3.png) no-repeat left top;
				}
			}
		}
	}
}

.menu_top {
	position: relative;
	padding-top: unset;
	background: rgba(0, 52, 112, .8);
	margin-bottom: unset;
	.container-vina	{
		.row {
			.menu {
				text-align: center;
				ul {
					float: none;
					li {
						a {
							color: #FFF;
							font-family: "UTM AvoBold" ,sans-serif;
							text-transform: uppercase;
							line-height: 45px;
							padding: 0 40px;
						}
						a:hover {
							background: unset;
							color: $main_color;
						}
						ul {
							top: 45px;
							text-align: left;
							background: rgba(0, 52, 112, .8);
							li {
								>a {
									font-size: 14px;
									padding: 0 15px;
									i {
										margin-top: 15px;
									}
								}
								a:hover {
									background: rgba(0, 0, 0, .2);
								}
								ul {
									top: 0px;
									text-align: left;
									li {
										a {
											font-size: 14px;
											text-transform: inherit;
										}
									}
								}
							}
						}
					}
					>li.active {
						>a {
							background: unset;
							color: $main_color;
						}
					}
				}
			}
		}
	}
}

.slider_box {
	position: relative;
	z-index: 1;
	margin-top: -45px;
	.homeslider {
		ul.slides {
			>li {
				.container-vina {					
					.more_div {
						width: 600px;
						left: calc(50% - 300px);
						top: 200px;
						text-align: center;
    					h2 {
    						font-size: 50px;
    						line-height: 60px;
    						margin-top: 0;
    						font-family: "UTM AvoBold", sans-serif;
    						color: #FFF;
    						text-shadow: 2px 2px 5px #353535;
    					}
    					.more2 {
    						font-size: 20px;
    						font-weight: 600;
    						margin-top: 25px;
    						font-family: "Roboto Condensed", sans-serif;
    						display: block;
    						color: #FFF;
    					}
    					.more:hover {
    						color: $main_color;
    					}
					}
				}
			}
		}

		.flex-control-paging {
			li {
				a:hover {
					background: #FFF !important;
				}
				a.flex-active {
					background: #FFF !important;
				}
			}
		}

		ul.flex-direction-nav {
			li {
				a {
					color: $main_color;
				}
			}
		}
	}
}

/* intro */
.intro {
	padding: 50px 0;
	.container-vina {
		.row {
			.left {
				p.title {
					display: inline-block;
					text-transform: uppercase;
					font-size: 25px;
					color: #000;
					font-family: "Roboto Condensed", sans-serif;
				}
				.swing {
					padding-left: 25px;
					img {
						width: 100%;
					}
				}
			}
			.middle {
				p {
					font-size: 15px;
					line-height: 24px;
					padding: 5px 0;
				}
			}
			.right {
				padding: 25px 0 0 25px;
				h2 {
					font-size: 20px;
					font-family: "Roboto Condensed", sans-serif;
					color: $main_color;
					font-style: italic;
					margin-bottom: 25px;
				}
				.swing {
					position: relative;
					transition:all 0.3s ease;
					img {
						border: none;
					}
				}
			    .swing:hover {
				    -webkit-animation: swingg 1s ease;
				    animation: swingg 1s ease;
				    -webkit-animation-iteration-count: 1;
				    animation-iteration-count: 1;
				}
			}
		}
	}
}

/* sản phẩm */
.cat_home {
	padding: 50px 0;
	background: #003471;
	.container-vina {
		padding: 0;
		.left {
			padding-right: 30px;
			p.title {
				position: relative;
				color: #FFF;
				font-size: 25px;
				font-family: "Roboto Condensed", sans-serif;
				text-transform: uppercase;
				padding-left: 50px;
				font-weight: bold;
				margin-bottom: 80px;
				span {
					font-size: 35px;
					font-family: "UTM AvoBold", sans-serif;
					margin-left: -50px;
				}
			}
			p.title::before {
				content: "";
				position: absolute;
				width: 60px;
				height: 4px;
				background: #FFF;
				bottom: -35px;
    			left: 0;
			}

			.list_title {
				ul {
	    			width: 100%;
	    			display: inline-block;
					li {
						padding: 10px 0 10px 20px;
						a {
							display: block;
							font-size: 15px;
							color: #FFF;
    						text-transform: uppercase;
    						text-decoration: none;
    						i {
    							color: $main_color;
    							margin-right: 10px;
    						}
						}
						a:hover {
							color: $main_color;
							i {color: #FFF;}
						}
						a.active {
							color: $main_color;
							i {color: #FFF;}
						}					
					}
				}
			}
		}		
		.listproduct {
			.nbs-flexisel-container {
				padding-top: 110px;
				position: relative;
				.nbs-flexisel-inner {
					ul#product_flex {
						li.item {
							dl {
								border: none;
								background: #FFF;
								margin: 0 7px;
								dt {
									padding: 0;
									.swing {
										height:unset;
										margin-bottom: 0;
										a {
											img {
												width: 100%;
												max-width: 100%;
    											max-height: 175px;
											}
										}
									}
								}
								dd {
									padding: 30px;
									>a {
										display: block;
										font-size: 15px;
										font-family: "Roboto Condensed", sans-serif;
										color: $main_color;
										margin-bottom: 5px;
    									text-transform: uppercase;
    									text-align: left;
    									font-weight: bold;
									}
									>a:hover {
										color: #000;
									}
									>h3 {
										font-size: 20px;
										font-family: "UTM AvoBold", sans-serif;
										text-transform: uppercase;
										height: 22px;
    									overflow: hidden;
										margin-bottom: 20px;
										>a {
											color: #000;
											text-decoration: none;
										}
										>a:hover {
											color: $main_color;
										}
									}
									p {
										font-size: 15px;
									    line-height: 22px;
									    height: 88px;
									    overflow: hidden;
									}
								}
							}
						}
					}					
				}
				.nbs-flexisel-nav-left {
					position: absolute;
					top: 15px !important;
					right: 20px;
					width: 12px;
					height: 12px;
					background: url(../images/icon_left.png) no-repeat left top;
				}
				.nbs-flexisel-nav-right {
					position: absolute;
					top: 15px !important;
					right: 0;
					width: 12px;
					height: 12px;
					background: url(../images/icon_right.png) no-repeat left top;
				}
			}			
		}
	}
}

/* tại sao */
.why_home {
	padding: 80px 0 55px;
	.container-vina	{
		.content {
			.left {
				p.title {
					position: relative;
					color: #000;
					font-size: 25px;
					font-family: "Roboto Condensed", sans-serif;
					text-transform: uppercase;
					padding-left: 50px;
					font-weight: bold;
					span {
						font-size: 35px;
						font-family: "UTM AvoBold", sans-serif;
						margin-left: -50px;
						color:$main_color;
					}
				}
				p.title::before {
					content: "";
					position: absolute;
					width: 60px;
					height: 4px;
					background: $main_color;
					bottom: -35px;
	    			left: 0;
				}
			}
			.right {
				.link {
					margin-bottom: 25px;
					>img {
						width: 55px;
						margin-bottom: 10px;
					}
					>h3 {
						font-size: 20px;
						font-family: "Roboto Condensed", sans-serif;
						text-transform: uppercase;
						font-weight: bold;
						margin-bottom: 10px;
						>a {
							color: #000;
							text-decoration: none;
						}
						>a:hover {
							color: $main_color;
						}
					}
					>p {

					}
				}
			}
		}
	}
}

/* tin tuc */
.new_home {
	padding: 70px 0;
	background: #F1F1F1;
	.container-vina {
		.row {
			.left {
				p.title {
					position: relative;
					color: #000;
					font-size: 25px;
					font-family: "Roboto Condensed", sans-serif;
					text-transform: uppercase;
					padding-left: 50px;
					font-weight: bold;
					span {
						font-size: 35px;
						font-family: "UTM AvoBold", sans-serif;
						margin-left: -50px;
						color:$second_color;
					}
					span:nth-child(3) {margin-left: -25px;}
				}
				p.title::before {
					content: "";
					position: absolute;
					width: 60px;
					height: 4px;
					background: $main_color;
					bottom: -35px;
	    			left: 0;
				}
			}
			.right {
				.item {
					text-align: center;
					padding: 30px;
					background: #003471;
					width: 32%;
					margin-right: 2%;
					.title {
						img {
							width: 100px;
							margin: 20px 0;
						}
						h3 {
							color: #FFF;
    						font-size: 20px;
    						font-family: "UTM AvoBold", sans-serif;
    						margin-bottom: 20px;
						}
					}
					.content {
						ul {
							text-align: left;
							li {
								padding: 10px 0;
								border-bottom: 1px solid $main_color;
								a {
									font-size: 15px;
    								color: #FFF;
    								line-height: 24px;
								}
								a:hover {
									color: $main_color;
								}
							}
							li:last-child {
								margin-bottom: 30px;
							}
						}
						>a.example {
							float: right;
						    color: #FFF;
						    text-decoration: none;
						    font-weight: bold;
						    font-style: italic;
						}
						>a.example:hover {
							color: $main_color;
						}
					}
				}
				.item:last-child {margin-right: 0;}
			}
		}
	}
}

/* hỗ trợ */
.support_home {
	padding: 70px 0;
	background: $main_color;
	position: relative;
    z-index: 10;
	.container-vina	{
		.row {
			.left {
				.title1 {
					font-size: 20px;
					font-family: "Roboto Condensed", sans-serif;
					color: #FFF;
					font-weight: bold;
					text-transform: uppercase;
					margin-bottom: 10px;
				}
				.title2 {
					font-size: 20px;
					font-family: "Roboto Condensed", sans-serif;
					color: #FFF;
					font-weight: bold;
					height: 28px;
					i {
						margin-right: 10px;
					}
				}
			}
			.right {
				position: relative;
				img {
					position: absolute;
    				bottom: -90px;
				}
				.swing {
					background: #FFF url(../images/icon_phone.png) no-repeat left top;
				    background-size: 40px;
				    background-position-x: 6px;
    				background-position-y: 6px;
				    float: right;
				    line-height: 53px;				    
				    padding: 0 20px 0 60px;
				    border-radius: 25px;
				    margin-top: 10px;
				    transition: all 0.3s ease;
				    a {
				    	font-size: 25px;
					    font-weight: bold;
					    color: #2C56A5;
					    text-decoration: none;
				    }
				}
				.swing:hover {
					-webkit-animation: swingg 1s ease;
			        animation: swingg 1s ease;
			        -webkit-animation-iteration-count: 1;
			        animation-iteration-count: 1;
				}
			}
		}
	}
}

/* thư viện ảnh */
.album_home {
	padding-top: unset;
	margin-top: unset;
	background: $second_color;
	.container-vina {
		border-top: unset;
		padding: 60px 0;
		.row {
			.left {
				p.title {
					position: relative;
					color: #FFF;
					font-size: 25px;
					font-family: "Roboto Condensed", sans-serif;
					text-transform: uppercase;
					padding-left: 50px;
					font-weight: bold;
					span {
						font-size: 35px;
						font-family: "UTM AvoBold", sans-serif;
						margin-left: -50px;
					}
				}
			}
			.right {
				margin-top: 20px;
				p {
					font-size: 15px;
					color: #FFF;
					line-height: 24px;
				}
			}
		}
	}
	.nbs-flexisel-container {
		padding-bottom: 100px;
		.nbs-flexisel-inner {
			ul#pic_flex {
				li {

				}
			}
		}
		.nbs-flexisel-nav-left {
			position: absolute;
			top: unset !important;
			bottom: 40px !important;
			left: calc(50% - 40px);
			width: 30px;
			height: 30px;
			background: url(../images/oprev.png) no-repeat left top;
		}
		.nbs-flexisel-nav-right {
			position: absolute;
			top: unset !important;
			bottom: 40px !important;
			right: calc(50% - 40px);
			width: 30px;
			height: 30px;
			background: url(../images/onext.png) no-repeat left top;
		}
	}
}

/* đối tác */
.partner_home {
	padding: 20px 0;
	.container-vina {
		.nbs-flexisel-container {
			.nbs-flexisel-inner {
				text-align: center;
			}
		}
	}
}

/* bản đồ */
#mapcont {
	border-bottom: 12px solid $main_color;
}

/* chân site */
.top_footer {
	padding: 50px 0 20px;
	background: #000;
	.container-vina {
		.row {
			.cot {
				>h3 {
					font-family: "Roboto Condensed", sans-serif;
					font-size: 24px;
					color: $main_color;
				}
				>h3::before {
					top: 50px;
					border-top: 3px solid $main_color;
				}
			}
			.address {
				>p {
					font-family: "UTM AvoBold", sans-serif;
					font-size: 15px;
				}
			}
			.menu_footer {
				div {
					ul {
						li {
							a {
								font-family: "UTM AvoBold", sans-serif;
							}
							a:hover {
								color: $main_color;
							}
						}
					}
				}
			}
			.registration {
				.content {
					width: 80%;
    				margin: 0 auto;
    				padding: 30px;
    				border: 5px solid #FFF;
    				background: $main_color;
    				h3.title {
    					text-align: center;
					    font-size: 20px;
					    font-family: "UTM AvoBold", sans-serif;
					    text-transform: uppercase;
					    margin-bottom: 20px;
    				}
    				.input-group {
    					#newsletter {
    						text-align: center;
    						input {
    							box-shadow: unset;
    							border: none;
    							background: unset;
    							border-radius: 0;
    							border-bottom: 1px solid #FFF;
    							margin-bottom: 15px;
    							color: #FFF !important;
    						}
    						input::-webkit-input-placeholder {
    							color: #FFF !important;
    							font-style: italic;
    						}
    						.btn {
    							margin-top: 20px;
    							border: none;
    							background: $second_color;
    							padding: 10px 25px;
							    text-transform: uppercase;
							    font-weight: bold;
							    border-radius: 3px;
    						}
    						.swing {
						    	position: relative;
								transition:all 0.3s ease;
						    }
						    .swing:hover {
							    -webkit-animation: swingg 1s ease;
							    animation: swingg 1s ease;
							    -webkit-animation-iteration-count: 1;
							    animation-iteration-count: 1;
							}
    					}
    				}
				}
			}
		}		
	}
}

.bottom_page {
	.container-vina {
		border-top: 1px solid #252525;
		.item {
			p {
				color: #FFF;
				font-weight: bold;
				a:hover {color: #000;}
			}
		}
		.go-top {
			a {
				margin-top: 20px;
				i {
					background: $main_color;
					color: #000;
					font-size: 14px;
				    width: 20px;
				    height: 20px;
				    border-radius: 20px;
				    padding-top: 3px;
				    padding-left: 4px;
				}
			}			
		}
	}
}

/* các trang con */

/* cột trái tìm kiếm */
.left {
	.left_n {
		.search_left {
			padding: 10px;
			.wrap_form {
				position: relative;
				.position {
					top: 48px;;
					>p {
						padding: 10px;
						background: unset;
						color: $main_color;
					}
					>div {
						width: 100%;
						padding: 10px;
						.s_img {
							width: 90px;
						}
					}
				}
			}
		}
	}
}

/* trang sản phẩm */
.listproduct {
	>.item {
		>.box_p {
			>.scale {
				img {
					max-width: 90%;
    				max-height: 90%;
    				@include transition_5s;
				}
			}
			>.scale:hover {
				img {
					-webkit-transform: scale(1.05);
				    -moz-transform: scale(1.05);
				    -ms-transform: scale(1.05);
				    -o-transform: scale(1.05);
				    transform: scale(1.05);
				}
			}
		}
		.box_p:hover {
			box-shadow: 0 0 10px 0 $main_color;
		    -webkit-box-shadow: 0 0 10px 0 $main_color;
		    -moz-box-shadow: 0 0 10px 0 $main_color;
		}
	}
}

.content {
	.container {
		.product_subpage {
			.product_page {
				.right_p {
					.brief_ppage {
						.shopcart_form {
							.form_row {
								.button_cart {
									button {
										background: -webkit-gradient(linear, left top, left bottom, from($main_color), to($three_color));
    									background: linear-gradient(to bottom, $three_color, $main_color)
									}
								}
							}
						}
					}
				}
			}
		}
		.policy {
			padding-bottom: 0 !important;
			.hotline {
				padding: 10px;
				background: $main_color;
				color: #FFF;
				.title {
					font-size: 16px;
					padding-left: 0px;
					background: unset;
					color: #FFF;
					text-align: center;
				}
				.phone {
					font-size: 32px;
					font-weight: bold;
					font-family: "Roboto Condensed", sans-serif;
					background: url(../images/phone.png) no-repeat left top;
					padding-left: 60px;
					line-height: 50px;
					height: 50px;
					display: block;
					a {
						color: #FFF;
					}
					a:hover {
						color: #000;
					}
				}
				.swing {
			    	position: relative;
					transition:all 0.3s ease;
			    }
			    .swing:hover {
				    -webkit-animation: swingg 1s ease;
				    animation: swingg 1s ease;
				    -webkit-animation-iteration-count: 1;
				    animation-iteration-count: 1;
				}
			}
		}
	}
}

/* menu sp trái */
.menuleft_box {
	div {
		>ul {
			>li {
				>a {
					display: unset;
				}
				>i {
					float: right;
    				font-size: 18px;
    				margin-top: 4px;
				}
				>i:hover {
					color: $main_color;
				}
				>ul {
					display: none;
					padding-left: 15px;
					>li {
						padding: 5px 0;
						a {}
					}
				}
			}
		}
	}
}


/* mobile trang chủ */

@media screen and (max-width: 599px) {

	/* intro */
	.intro {
		padding: 20px 10px;
		.container-vina {
			.row {
				.left {
					.swing {
						padding-left: 0;
						text-align: center;
						img {
							width: 60%;
							margin: 0 auto;
						}
					}
				}
				.right {
					padding: 25px 0 0;
					h2 {
					}
					.swing {
						text-align: center;
						img {
							width: 60%;
							margin: 0 auto;
						}
					}
				}
			}
		}
	}

	/* sản phẩm */
	.cat_home {
		padding: 20px 10px;
		.container-vina {
			.left {
				padding-right: 0;
				p.title {
					font-size: 22px;
					margin-bottom: 30px;
					span {
						font-size: 30px;
						font-family: "UTM AvoBold", sans-serif;
						margin-left: -40px;
					}
				}
				p.title::before {
					width: 40px;
					bottom: -15px;
				}

				.list_title {
					ul {
						li {
							a {
								padding: 0;
								border: none;
							}
						}
					}
				}
			}		
			.listproduct {
				.nbs-flexisel-container {
					padding-top: 40px;
					.nbs-flexisel-inner {
						ul#product_flex {
							li.item {
								dl {
									margin: 0;
								}
							}
						}					
					}
					.nbs-flexisel-nav-left {
						position: absolute;
						top: 15px !important;
						right: 20px;
						width: 12px;
						height: 12px;
						background: url(../images/icon_left.png) no-repeat left top;
					}
					.nbs-flexisel-nav-right {
						position: absolute;
						top: 15px !important;
						right: 0;
						width: 12px;
						height: 12px;
						background: url(../images/icon_right.png) no-repeat left top;
					}
				}			
			}
		}
	}

	/* tại sao */
	.why_home {
		padding: 20px 10px;
		.container-vina	{
			.content {
				.left {
					p.title {
						font-size: 22px;
						padding-left: 40px;
						margin-bottom: 30px;
						span {
							font-size: 30px;
							font-family: "UTM AvoBold", sans-serif;
							margin-left: -40px;
							color:$main_color;
						}
					}
					p.title::before {
						bottom: -15px;
					}
				}
				.right {
					.link {
						margin-bottom: 15px;
						padding: 0;
					}
				}
			}
		}
	}

	/* tin tuc */
	.new_home {
		padding: 20px 10px;
		.container-vina {
			.row {
				.left {
					p.title {
						font-size: 22px;
						padding-left: 40px;
						font-weight: bold;
						margin-bottom: 30px;
						span {
							font-size: 30px;
							margin-left: -40px;
						}
						span:nth-child(3) {margin-left: -20px;}
					}
					p.title::before {
						bottom: -10px;
					}
				}
				.right {
					.item {
						padding: 15px;
						width: 100%;
						margin-right: 0;
						.title {
							img {
								width: 100px;
								margin: 10px 0;
							}
							h3 {
	    						margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}

	/* hỗ trợ */
	.support_home {
		padding: 20px 10px;
		.container-vina	{
			.row {
				.left {
					.title2 {
						height: 56px;
					}
				}
				.right {
					position: relative;
					img {
						display: none;
					}
				}
			}
		}
	}

	/* thư viện ảnh */
	.album_home {
		.container-vina {
			border-top: unset;
			padding: 30px 10px;
			.row {
				.left {
					p.title {
						font-size: 22px;
						padding-left: 40px;
						span {
							font-size: 30px;
							margin-left: -40px;
						}
					}
				}
			}
		}
	}

	/* đối tác */
	.partner_home {
	}

	

	/* chân site */
	.top_footer {
		padding: 20px 10px;
		.container-vina {
			.row {
				.cot {
					>h3 {
						margin-bottom: 20px;
					}
					>h3::before {
						top: 30px;
					}
				}
				.registration {
					.content {
	    				margin-top: 0 !important;
	    				width: 100%;
					}
				}
			}		
		}
	}

	.subpage {
		.container-vina {
			.row {
				.right {
					.subbox {
						.listproduct {
							.item {
								width: 49%;
    							margin-right: 2%;
    							dl {
    								dt {
    									h3 {
    										height: 40px;
    									}
    								}
    							}
							}
							.item:nth-child(2n+2) {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}

}
